import React from 'react'
import propTypes from 'prop-types'
import { alert } from './styles.module.scss'

const Alert = props => {
  return (
    <div {...props} className={`${alert} ${props.className || ''}${props.withBackground ? ' with-background' : ''}`} size={props.size || ''}>
      <span className="title">{props.title || 'No Title'}</span>
      <span className="body">{props.body || 'No Description'}</span>
    </div>
  )
}

Alert.propTypes = {
  size: propTypes.string,
  className: propTypes.string,
}

export default Alert
