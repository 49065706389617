// extracted by mini-css-extract-plugin
export const downloadAppContainer = "basaas-app-module__downloadAppContainer--2ESdL";
export const item = "item";
export const title = "title";
export const infoContainer = "basaas-app-module__infoContainer--Wch5G";
export const infoBody = "info-body";
export const list = "list";
export const listItem = "list-item";
export const itemIcon = "item-icon";
export const itemContent = "item-content";
export const ctaContainer = "cta-container";
export const paragraph = "paragraph";